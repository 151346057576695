import * as actionTypes from './actionTypes';
import { EmailActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';

export type EmailStateType = {
  subscribeNewsletterLoading: boolean;
  subscribeNewsletterError: HttpError | null;
  subscribeNewsletterSuccess: boolean;
};

export type EmailActionType = EmailStateType & {
  type: EmailActionTypes;
};

export const initialState: EmailStateType = {
  subscribeNewsletterLoading: false,
  subscribeNewsletterError: null,
  subscribeNewsletterSuccess: false,
};

const createPublicEmailStart = (state: EmailStateType): EmailStateType => ({
  ...state,
  subscribeNewsletterLoading: true,
});

const createPublicEmailSuccess = (
  state: EmailStateType,
  action: EmailActionType,
): EmailStateType => ({
  ...state,
  subscribeNewsletterLoading: false,
  subscribeNewsletterError: null,
  subscribeNewsletterSuccess: true,
});

const createPublicEmailFail = (
  state: EmailStateType,
  action: EmailActionType,
): EmailStateType => ({
  ...state,
  subscribeNewsletterLoading: false,
  subscribeNewsletterError: action.subscribeNewsletterError,
});

const resetEmailStore = (): EmailStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: EmailActionType) => {
  switch (action.type) {
    case actionTypes.SUBSCRIBE_NEWSLETTER_START:
      return createPublicEmailStart(state);
    case actionTypes.SUBSCRIBE_NEWSLETTER_SUCCESS:
      return createPublicEmailSuccess(state, action);
    case actionTypes.SUBSCRIBE_NEWSLETTER_FAIL:
      return createPublicEmailFail(state, action);
    case actionTypes.RESET_EMAIL_STORE:
      return resetEmailStore();
    case actionTypes.LOGOUT:
      return resetEmailStore();
    default:
      return state;
  }
};

export default reducer;
