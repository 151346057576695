export const CREATE_PUBLIC_ENQUIRY_START = 'CREATE_PUBLIC_ENQUIRY_START';
export const CREATE_PUBLIC_ENQUIRY_SUCCESS = 'CREATE_PUBLIC_ENQUIRY_SUCCESS';
export const CREATE_PUBLIC_ENQUIRY_FAIL = 'CREATE_PUBLIC_ENQUIRY_FAIL';

export const RESET_ENQUIRY_STORE = 'RESET_ENQUIRY_STORE';

export const LOGOUT = 'LOGOUT';

export type EnquiryActionTypes =
  | typeof CREATE_PUBLIC_ENQUIRY_START
  | typeof CREATE_PUBLIC_ENQUIRY_SUCCESS
  | typeof CREATE_PUBLIC_ENQUIRY_FAIL
  | typeof RESET_ENQUIRY_STORE
  | typeof LOGOUT;
