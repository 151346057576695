import React, { ReactNode } from 'react';
import styles from './Modal.module.scss';
import Button from '../../common/Button/Button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import cx from 'classnames';
import { translate } from '../../utility/messageTranslator/translate';
import { useIntl } from 'react-intl';

type Props = {
  onConfirm?: () => void;
  onClose: () => void;
  isLoading?: boolean;
  isOpen: boolean;
  explanationText?: string | undefined;
  title?: string;
  children?: ReactNode;
  className?: string;
  isSmall?: boolean;
};

const Modal = ({
  isOpen,
  onConfirm,
  onClose,
  explanationText,
  title,
  isLoading,
  children,
  className,
  isSmall,
}: Props) => {
  const intl = useIntl();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={cx(
        styles.modalContainer,
        {
          [styles.isSmall]: !!onConfirm || isSmall,
        },
        className,
      )}
    >
      {title && (
        <DialogTitle id="alert-dialog-title" className={styles.modalTitle}>
          {title}
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
      )}

      <DialogContent>
        {explanationText && (
          <DialogContentText id="alert-dialog-description">
            {explanationText}
          </DialogContentText>
        )}
        {children}
      </DialogContent>
      {onConfirm && (
        <DialogActions>
          <Button isConfirmationButton onClick={onClose}>
            {translate(intl, 'MODAL.CANCEL')}
          </Button>
          <Button
            isConfirmationButton
            onClick={onConfirm}
            isLoading={isLoading}
          >
            {translate(intl, 'MODAL.CONFIRM')}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default Modal;
