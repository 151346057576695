export const SUBSCRIBE_NEWSLETTER_START = 'SUBSCRIBE_NEWSLETTER_START';
export const SUBSCRIBE_NEWSLETTER_SUCCESS = 'SUBSCRIBE_NEWSLETTER_SUCCESS';
export const SUBSCRIBE_NEWSLETTER_FAIL = 'SUBSCRIBE_NEWSLETTER_FAIL';

export const RESET_EMAIL_STORE = 'RESET_EMAIL_STORE';

export const LOGOUT = 'LOGOUT';

export type EmailActionTypes =
  | typeof SUBSCRIBE_NEWSLETTER_START
  | typeof SUBSCRIBE_NEWSLETTER_SUCCESS
  | typeof SUBSCRIBE_NEWSLETTER_FAIL
  | typeof RESET_EMAIL_STORE
  | typeof LOGOUT;
