import { IntlShape } from 'react-intl';
import axios from '../../config/Axios/axios-instance';
import {
  subscribeNewsletterStart,
  subscribeNewsletterSuccess,
  subscribeNewsletterFail,
} from './actions';
import { Dispatch } from 'redux';
import { showToast } from '../../utility/toast/toast';
import { translate } from '../../utility/messageTranslator/translate';

const API_URL = '/email';

export type SubscribeNewsletterRequest = {
  firstName: string;
  email: string;
};

export const subscribeNewsletter =
  (inputs: SubscribeNewsletterRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(subscribeNewsletterStart());
    return axios
      .post(`${API_URL}/subscribe-newsletter`, {
        ...inputs,
      })
      .then((response) => {
        dispatch(subscribeNewsletterSuccess());
        showToast(translate(intl, 'SUCCESS.SUBSCRIBE_NEWSLETTER'), 'success');
      })
      .catch((err) => {
        dispatch(subscribeNewsletterFail(err?.response?.data?.message));
      });
  };
