import * as actionTypes from './actionTypes';
import { SessionTypeActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { SessionType } from '../../domain/SessionType';
import { ListResults } from '../../common/List/List';

export type SessionTypeStateType = {
  sessionTypesList: ListResults<SessionType> | null;
  sessionTypesLoading: boolean;
  sessionTypesError: HttpError;
  sessionTypeCreateLoading: boolean;
  sessionTypeCreateError: HttpError;
  sessionTypeCreateSuccess: boolean;
  sessionTypeUpdateLoading: boolean;
  sessionTypeUpdateError: HttpError;
  sessionTypeUpdateSuccess: boolean;
  sessionTypeDeleteLoading: boolean;
  sessionTypeDeleteError: HttpError;
  sessionTypeDeleteSuccess: boolean;
  sessionTypesListUpdateNeeded: boolean;
  createdSessionType: SessionType | null;
  allSessionTypes: SessionType[] | null;
  allSessionTypesLoading: boolean;
  allSessionTypesError: HttpError;
  availableSessionTypes: SessionType[];
  availableSessionTypesLoading: boolean;
  availableSessionTypesError: HttpError;
};

export type SessionTypeActionType = SessionTypeStateType & {
  type: SessionTypeActionTypes;
};

export const initialState: SessionTypeStateType = {
  sessionTypeCreateError: null,
  sessionTypeCreateLoading: false,
  sessionTypeCreateSuccess: false,
  sessionTypeDeleteError: null,
  sessionTypeDeleteLoading: false,
  sessionTypeDeleteSuccess: false,
  sessionTypeUpdateError: null,
  sessionTypeUpdateLoading: false,
  sessionTypeUpdateSuccess: false,
  sessionTypesError: null,
  sessionTypesList: null,
  sessionTypesLoading: true,
  sessionTypesListUpdateNeeded: false,
  createdSessionType: null,
  allSessionTypesError: null,
  allSessionTypes: null,
  allSessionTypesLoading: false,
  availableSessionTypesError: null,
  availableSessionTypes: [],
  availableSessionTypesLoading: false,
};

const fetchSessionTypesStart = (
  state: SessionTypeStateType,
): SessionTypeStateType => ({
  ...state,
  sessionTypesLoading: true,
  sessionTypeUpdateSuccess: false,
  sessionTypeCreateSuccess: false,
  sessionTypeDeleteSuccess: false,
});

const fetchSessionTypesSuccess = (
  state: SessionTypeStateType,
  action: SessionTypeActionType,
): SessionTypeStateType => ({
  ...state,
  sessionTypesList: action.sessionTypesList,
  sessionTypesLoading: false,
  sessionTypesError: null,
  sessionTypesListUpdateNeeded: false,
});

const fetchSessionTypesFail = (
  state: SessionTypeStateType,
  action: SessionTypeActionType,
): SessionTypeStateType => ({
  ...state,
  sessionTypesError: action.sessionTypesError,
  sessionTypesLoading: false,
});

const fetchAllSessionTypesStart = (
  state: SessionTypeStateType,
): SessionTypeStateType => ({
  ...state,
  allSessionTypesLoading: true,
});

const fetchAllSessionTypesSuccess = (
  state: SessionTypeStateType,
  action: SessionTypeActionType,
): SessionTypeStateType => ({
  ...state,
  allSessionTypes: action.allSessionTypes,
  allSessionTypesLoading: false,
  allSessionTypesError: null,
});

const fetchAllSessionTypesFail = (
  state: SessionTypeStateType,
  action: SessionTypeActionType,
): SessionTypeStateType => ({
  ...state,
  allSessionTypesError: action.allSessionTypesError,
  allSessionTypesLoading: false,
});

const fetchAvailableSessionTypesStart = (
  state: SessionTypeStateType,
): SessionTypeStateType => ({
  ...state,
  availableSessionTypesLoading: true,
});

const fetchAvailableSessionTypesSuccess = (
  state: SessionTypeStateType,
  action: SessionTypeActionType,
): SessionTypeStateType => ({
  ...state,
  availableSessionTypes: action.availableSessionTypes,
  availableSessionTypesLoading: false,
  availableSessionTypesError: null,
});

const fetchAvailableSessionTypesFail = (
  state: SessionTypeStateType,
  action: SessionTypeActionType,
): SessionTypeStateType => ({
  ...state,
  availableSessionTypesError: action.availableSessionTypesError,
  availableSessionTypesLoading: false,
});

const createSessionTypeStart = (
  state: SessionTypeStateType,
): SessionTypeStateType => ({
  ...state,
  sessionTypeCreateLoading: true,
});

const createSessionTypeSuccess = (
  state: SessionTypeStateType,
  action: SessionTypeActionType,
): SessionTypeStateType => ({
  ...state,
  sessionTypeCreateLoading: false,
  sessionTypeCreateError: null,
  sessionTypeCreateSuccess: true,
  sessionTypesListUpdateNeeded: true,
  createdSessionType: action.createdSessionType,
});

const createSessionTypeFail = (
  state: SessionTypeStateType,
  action: SessionTypeActionType,
): SessionTypeStateType => ({
  ...state,
  sessionTypeCreateLoading: false,
  sessionTypeCreateError: action.sessionTypeCreateError,
});

const updateSessionTypeStart = (
  state: SessionTypeStateType,
): SessionTypeStateType => ({
  ...state,
  sessionTypeUpdateLoading: true,
});

const updateSessionTypeSuccess = (
  state: SessionTypeStateType,
): SessionTypeStateType => ({
  ...state,
  sessionTypeUpdateLoading: false,
  sessionTypeUpdateError: null,
  sessionTypeUpdateSuccess: true,
  sessionTypesListUpdateNeeded: true,
});

const updateSessionTypeFail = (
  state: SessionTypeStateType,
  action: SessionTypeActionType,
): SessionTypeStateType => ({
  ...state,
  sessionTypeUpdateLoading: false,
  sessionTypeUpdateError: action.sessionTypeUpdateError,
});

const deleteSessionTypeStart = (
  state: SessionTypeStateType,
): SessionTypeStateType => ({
  ...state,
  sessionTypeDeleteLoading: true,
});

const deleteSessionTypeSuccess = (
  state: SessionTypeStateType,
): SessionTypeStateType => ({
  ...state,
  sessionTypeDeleteLoading: false,
  sessionTypeDeleteError: null,
  sessionTypeDeleteSuccess: true,
  sessionTypesListUpdateNeeded: true,
});

const deleteSessionTypeFail = (
  state: SessionTypeStateType,
  action: SessionTypeActionType,
): SessionTypeStateType => ({
  ...state,
  sessionTypeDeleteLoading: false,
  sessionTypeDeleteError: action.sessionTypeDeleteError,
});

const resetSessionTypeStore = (
  state: SessionTypeStateType,
): SessionTypeStateType => ({
  ...initialState,
});

const completelyResetSessionTypeStore = (): SessionTypeStateType => ({
  ...initialState,
});

const resetCreateSessionTypeStore = (
  state: SessionTypeStateType,
): SessionTypeStateType => ({
  ...state,
  createdSessionType: null,
});

const reducer = (state = initialState, action: SessionTypeActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_SESSION_TYPES_START:
      return fetchSessionTypesStart(state);
    case actionTypes.FETCH_SESSION_TYPES_SUCCESS:
      return fetchSessionTypesSuccess(state, action);
    case actionTypes.FETCH_SESSION_TYPES_FAIL:
      return fetchSessionTypesFail(state, action);
    case actionTypes.FETCH_ALL_SESSION_TYPES_START:
      return fetchAllSessionTypesStart(state);
    case actionTypes.FETCH_ALL_SESSION_TYPES_SUCCESS:
      return fetchAllSessionTypesSuccess(state, action);
    case actionTypes.FETCH_ALL_SESSION_TYPES_FAIL:
      return fetchAllSessionTypesFail(state, action);
    case actionTypes.FETCH_AVAILABLE_SESSION_TYPES_START:
      return fetchAvailableSessionTypesStart(state);
    case actionTypes.FETCH_AVAILABLE_SESSION_TYPES_SUCCESS:
      return fetchAvailableSessionTypesSuccess(state, action);
    case actionTypes.FETCH_AVAILABLE_SESSION_TYPES_FAIL:
      return fetchAvailableSessionTypesFail(state, action);
    case actionTypes.CREATE_SESSION_TYPE_START:
      return createSessionTypeStart(state);
    case actionTypes.CREATE_SESSION_TYPE_SUCCESS:
      return createSessionTypeSuccess(state, action);
    case actionTypes.CREATE_SESSION_TYPE_FAIL:
      return createSessionTypeFail(state, action);
    case actionTypes.UPDATE_SESSION_TYPE_START:
      return updateSessionTypeStart(state);
    case actionTypes.UPDATE_SESSION_TYPE_SUCCESS:
      return updateSessionTypeSuccess(state);
    case actionTypes.UPDATE_SESSION_TYPE_FAIL:
      return updateSessionTypeFail(state, action);
    case actionTypes.DELETE_SESSION_TYPE_START:
      return deleteSessionTypeStart(state);
    case actionTypes.DELETE_SESSION_TYPE_SUCCESS:
      return deleteSessionTypeSuccess(state);
    case actionTypes.DELETE_SESSION_TYPE_FAIL:
      return deleteSessionTypeFail(state, action);
    case actionTypes.RESET_SESSION_TYPE_STORE:
      return resetSessionTypeStore(state);
    case actionTypes.RESET_CREATED_SESSION_TYPE:
      return resetCreateSessionTypeStore(state);
    case actionTypes.LOGOUT:
      return completelyResetSessionTypeStore();
    default:
      return state;
  }
};

export default reducer;
