export const FETCH_SESSION_TYPES_START = 'FETCH_SESSION_TYPES_START';
export const FETCH_SESSION_TYPES_SUCCESS = 'FETCH_SESSION_TYPES_SUCCESS';
export const FETCH_SESSION_TYPES_FAIL = 'FETCH_SESSION_TYPES_FAIL';

export const FETCH_ALL_SESSION_TYPES_START = 'FETCH_ALL_SESSION_TYPES_START';
export const FETCH_ALL_SESSION_TYPES_SUCCESS =
  'FETCH_ALL_SESSION_TYPES_SUCCESS';
export const FETCH_ALL_SESSION_TYPES_FAIL = 'FETCH_ALL_SESSION_TYPES_FAIL';

export const FETCH_AVAILABLE_SESSION_TYPES_START =
  'FETCH_AVAILABLE_SESSION_TYPES_START';
export const FETCH_AVAILABLE_SESSION_TYPES_SUCCESS =
  'FETCH_AVAILABLE_SESSION_TYPES_SUCCESS';
export const FETCH_AVAILABLE_SESSION_TYPES_FAIL =
  'FETCH_AVAILABLE_SESSION_TYPES_FAIL';

export const CREATE_SESSION_TYPE_START = 'CREATE_SESSION_TYPE_START';
export const CREATE_SESSION_TYPE_SUCCESS = 'CREATE_SESSION_TYPE_SUCCESS';
export const CREATE_SESSION_TYPE_FAIL = 'CREATE_SESSION_TYPE_FAIL';

export const UPDATE_SESSION_TYPE_START = 'UPDATE_SESSION_TYPE_START';
export const UPDATE_SESSION_TYPE_SUCCESS = 'UPDATE_SESSION_TYPE_SUCCESS';
export const UPDATE_SESSION_TYPE_FAIL = 'UPDATE_SESSION_TYPE_FAIL';

export const DELETE_SESSION_TYPE_START = 'DELETE_SESSION_TYPE_START';
export const DELETE_SESSION_TYPE_SUCCESS = 'DELETE_SESSION_TYPE_SUCCESS';
export const DELETE_SESSION_TYPE_FAIL = 'DELETE_SESSION_TYPE_FAIL';

export const RESET_SESSION_TYPE_STORE = 'RESET_SESSION_TYPE_STORE';
export const RESET_CREATED_SESSION_TYPE = 'RESET_CREATED_SESSION_TYPE';

export const LOGOUT = 'LOGOUT';

export type SessionTypeActionTypes =
  | typeof FETCH_SESSION_TYPES_START
  | typeof FETCH_SESSION_TYPES_SUCCESS
  | typeof FETCH_SESSION_TYPES_FAIL
  | typeof FETCH_ALL_SESSION_TYPES_START
  | typeof FETCH_ALL_SESSION_TYPES_SUCCESS
  | typeof FETCH_ALL_SESSION_TYPES_FAIL
  | typeof FETCH_AVAILABLE_SESSION_TYPES_START
  | typeof FETCH_AVAILABLE_SESSION_TYPES_SUCCESS
  | typeof FETCH_AVAILABLE_SESSION_TYPES_FAIL
  | typeof CREATE_SESSION_TYPE_START
  | typeof CREATE_SESSION_TYPE_SUCCESS
  | typeof CREATE_SESSION_TYPE_FAIL
  | typeof UPDATE_SESSION_TYPE_START
  | typeof UPDATE_SESSION_TYPE_SUCCESS
  | typeof UPDATE_SESSION_TYPE_FAIL
  | typeof DELETE_SESSION_TYPE_START
  | typeof DELETE_SESSION_TYPE_SUCCESS
  | typeof DELETE_SESSION_TYPE_FAIL
  | typeof RESET_SESSION_TYPE_STORE
  | typeof RESET_CREATED_SESSION_TYPE
  | typeof LOGOUT;
