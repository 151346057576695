import React, { useEffect } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import styles from './NewsletterSubscribeForm.module.scss';
import { useForm } from '../../../../hooks/useForm/useForm';
import { translate } from '../../../../utility/messageTranslator/translate';
import TextField from '../../../../common/TextField/TextField';
import Button from '../../../../common/Button/Button';
import Form from '../../../../common/Form/Form';
import { StoreState } from '../../../../config/StoreProvider/StoreProvider';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { SubscribeNewsletterRequest } from '../../../../store/email/service';
import { resetEmailStore } from '../../../../store/email/actions';
import { getGlobalError } from '../../../../utility/error/httpErrorParser';
import * as emailService from '../../../../store/email/service';
import { HttpError } from '../../../../config/Axios/axios-instance';
import Alert from '../../../../common/Alert/Alert';

type Props = {
  onSubscribeNewsletter: (
    inputs: SubscribeNewsletterRequest,
    intl: IntlShape,
  ) => void;
  onResetEmailStore: () => void;
  isSuccess: boolean;
  isLoading: boolean;
  error: HttpError;
  onClose: () => void;
};

type FormInputs = {
  firstName: string;
  email: string;
};

export const NewsletterSubscribeForm = ({
  onSubscribeNewsletter,
  onResetEmailStore,
  isSuccess,
  isLoading,
  error,
  onClose,
}: Props) => {
  const intl = useIntl();

  useEffect(() => {
    onResetEmailStore();
  }, []);

  const INPUTS = [
    {
      name: 'firstName',
      label: translate(intl, 'NEWSLETTER_SUBSCRIBE_FORM.FIRSTNAME_LABEL'),
      placeholder: translate(
        intl,
        'NEWSLETTER_SUBSCRIBE_FORM.FIRSTNAME_PLACEHOLDER',
      ),

      type: 'text',
      validation: [
        {
          type: 'required',
        },
        {
          type: 'minLength',
          parameter: 2,
        },
        {
          type: 'maxLength',
          parameter: 100,
        },
      ],
      value: '',
    },
    {
      name: 'email',
      label: translate(intl, 'NEWSLETTER_SUBSCRIBE_FORM.EMAIL_LABEL'),
      placeholder: translate(
        intl,
        'NEWSLETTER_SUBSCRIBE_FORM.EMAIL_PLACEHOLDER',
      ),
      type: 'text',
      validation: [
        {
          type: 'required',
        },
        {
          type: 'minLength',
          parameter: 6,
        },
        {
          type: 'maxLength',
          parameter: 200,
        },
      ],
      value: '',
    },
  ];

  const handleSubmit = (submitInputs: FormInputs) => {
    onSubscribeNewsletter(
      {
        firstName: submitInputs.firstName,
        email: submitInputs.email,
      },
      intl,
    );
  };

  const { inputs, onSubmit, onLoseInputFocus, onInputChange } = useForm(
    INPUTS,
    handleSubmit,
  );

  useEffect(() => {
    if (isSuccess) {
      localStorage.setItem('isNewsletterSubscribed', 'true');
      resetEmailStore();
      onClose();
    }
  }, [isSuccess]);

  const globalError = getGlobalError(error, intl);

  return (
    <Form
      className={styles.form}
      error={error}
      onSubmit={onSubmit}
      scrollIntoView={false}
    >
      {globalError && (
        <Alert variant="danger" capitalize={false}>
          {translate(intl, globalError)}
        </Alert>
      )}
      <div className={styles.formInputs}>
        {inputs.map((input) => (
          <TextField
            key={input.name}
            onChange={onInputChange}
            value={input.value?.toString() ?? ''}
            label={input.label ?? ''}
            placeholder={input.placeholder ?? ''}
            errors={input.validationErrors ?? []}
            onBlur={onLoseInputFocus}
            name={input.name}
            type={input.type}
            disabled={input.disabled}
            publicTheme
          />
        ))}
      </div>

      <Button
        isLoadingButton
        onClick={onSubmit}
        buttonVariant="contained"
        color="primary"
        type="submit"
        className={styles.submitButton}
        isLoading={isLoading}
      >
        {translate(intl, 'NEWSLETTER_SUBSCRIBE_FORM.SUBMIT_BUTTON')}
      </Button>
    </Form>
  );
};

const mapStateToProps = (state: StoreState) => ({
  isLoading: state.email.subscribeNewsletterLoading,
  isSuccess: state.email.subscribeNewsletterSuccess,
  error: state.email.subscribeNewsletterError,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onSubscribeNewsletter: (
    inputs: SubscribeNewsletterRequest,
    intl: IntlShape,
  ) => dispatch(emailService.subscribeNewsletter(inputs, intl)),
  onResetEmailStore: () => dispatch(resetEmailStore()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewsletterSubscribeForm);
