import * as actionTypes from './actionTypes';
import { ReservationActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { Reservation } from '../../domain/Reservation';

export type ReservationStateType = {
  userReservationsList: ListResults<Reservation> | null;
  userReservationsListLoading: boolean;
  userReservationsListError: HttpError;
};

export type ReservationActionType = ReservationStateType & {
  type: ReservationActionTypes;
};

export const initialState: ReservationStateType = {
  userReservationsList: null,
  userReservationsListLoading: false,
  userReservationsListError: null,
};

const fetchUserReservationsListStart = (
  state: ReservationStateType,
): ReservationStateType => ({
  ...state,
  userReservationsListLoading: true,
});

const fetchUserReservationsListSuccess = (
  state: ReservationStateType,
  action: ReservationActionType,
): ReservationStateType => ({
  ...state,
  userReservationsList: action.userReservationsList,
  userReservationsListLoading: false,
});

const fetchUserReservationsListFail = (
  state: ReservationStateType,
  action: ReservationActionType,
): ReservationStateType => ({
  ...state,
  userReservationsListError: action.userReservationsListError,
  userReservationsListLoading: false,
});

const resetReservationStore = (): ReservationStateType => ({
  ...initialState,
});

const logout = (): ReservationStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: ReservationActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_RESERVATIONS_LIST_START:
      return fetchUserReservationsListStart(state);
    case actionTypes.FETCH_USER_RESERVATIONS_LIST_SUCCESS:
      return fetchUserReservationsListSuccess(state, action);
    case actionTypes.FETCH_USER_RESERVATIONS_LIST_FAIL:
      return fetchUserReservationsListFail(state, action);
    case actionTypes.RESET_RESERVATION_STORE:
      return resetReservationStore();
    case actionTypes.LOGOUT:
      return logout();
    default:
      return state;
  }
};

export default reducer;
