import * as actionTypes from './actionTypes';
import { EnquiryActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';

export type EnquiryStateType = {
  publicEnquiryCreateLoading: boolean;
  publicEnquiryCreateError: HttpError | null;
  publicEnquiryCreateSuccess: boolean;
};

export type EnquiryActionType = EnquiryStateType & {
  type: EnquiryActionTypes;
};

export const initialState: EnquiryStateType = {
  publicEnquiryCreateLoading: false,
  publicEnquiryCreateError: null,
  publicEnquiryCreateSuccess: false,
};

const createPublicEnquiryStart = (
  state: EnquiryStateType,
): EnquiryStateType => ({
  ...state,
  publicEnquiryCreateLoading: true,
});

const createPublicEnquirySuccess = (
  state: EnquiryStateType,
  action: EnquiryActionType,
): EnquiryStateType => ({
  ...state,
  publicEnquiryCreateLoading: false,
  publicEnquiryCreateError: null,
  publicEnquiryCreateSuccess: true,
});

const createPublicEnquiryFail = (
  state: EnquiryStateType,
  action: EnquiryActionType,
): EnquiryStateType => ({
  ...state,
  publicEnquiryCreateLoading: false,
  publicEnquiryCreateError: action.publicEnquiryCreateError,
});

const resetEnquiryStore = (): EnquiryStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: EnquiryActionType) => {
  switch (action.type) {
    case actionTypes.CREATE_PUBLIC_ENQUIRY_START:
      return createPublicEnquiryStart(state);
    case actionTypes.CREATE_PUBLIC_ENQUIRY_SUCCESS:
      return createPublicEnquirySuccess(state, action);
    case actionTypes.CREATE_PUBLIC_ENQUIRY_FAIL:
      return createPublicEnquiryFail(state, action);
    case actionTypes.RESET_ENQUIRY_STORE:
      return resetEnquiryStore();
    case actionTypes.LOGOUT:
      return resetEnquiryStore();
    default:
      return state;
  }
};

export default reducer;
