import * as actionTypes from './actionTypes';
import { SubscriptionTypeActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { SubscriptionType } from '../../domain/SubscriptionType';
import { ListResults } from '../../common/List/List';

export type SubscriptionTypeStateType = {
  subscriptionTypesList: ListResults<SubscriptionType> | null;
  subscriptionTypesLoading: boolean;
  subscriptionTypesError: HttpError;
  subscriptionTypeCreateLoading: boolean;
  subscriptionTypeCreateError: HttpError;
  subscriptionTypeCreateSuccess: boolean;
  subscriptionTypeUpdateLoading: boolean;
  subscriptionTypeUpdateError: HttpError;
  subscriptionTypeUpdateSuccess: boolean;
  subscriptionTypeDeleteLoading: boolean;
  subscriptionTypeDeleteError: HttpError;
  subscriptionTypeDeleteSuccess: boolean;
  subscriptionTypesListUpdateNeeded: boolean;
  createdSubscriptionType: SubscriptionType | null;
  allSubscriptionTypes: SubscriptionType[] | null;
  allSubscriptionTypesError: HttpError;
  allSubscriptionTypesLoading: boolean;
};

export type SubscriptionTypeActionType = SubscriptionTypeStateType & {
  type: SubscriptionTypeActionTypes;
};

export const initialState: SubscriptionTypeStateType = {
  subscriptionTypeCreateError: null,
  subscriptionTypeCreateLoading: false,
  subscriptionTypeCreateSuccess: false,
  subscriptionTypeDeleteError: null,
  subscriptionTypeDeleteLoading: false,
  subscriptionTypeDeleteSuccess: false,
  subscriptionTypeUpdateError: null,
  subscriptionTypeUpdateLoading: false,
  subscriptionTypeUpdateSuccess: false,
  subscriptionTypesError: null,
  subscriptionTypesList: null,
  subscriptionTypesLoading: true,
  subscriptionTypesListUpdateNeeded: false,
  createdSubscriptionType: null,
  allSubscriptionTypesError: null,
  allSubscriptionTypes: null,
  allSubscriptionTypesLoading: false,
};

const fetchSubscriptionTypesStart = (
  state: SubscriptionTypeStateType,
): SubscriptionTypeStateType => ({
  ...state,
  subscriptionTypesLoading: true,
  subscriptionTypeUpdateSuccess: false,
  subscriptionTypeCreateSuccess: false,
  subscriptionTypeDeleteSuccess: false,
});

const fetchSubscriptionTypesSuccess = (
  state: SubscriptionTypeStateType,
  action: SubscriptionTypeActionType,
): SubscriptionTypeStateType => ({
  ...state,
  subscriptionTypesList: action.subscriptionTypesList,
  subscriptionTypesLoading: false,
  subscriptionTypesError: null,
  subscriptionTypesListUpdateNeeded: false,
});

const fetchSubscriptionTypesFail = (
  state: SubscriptionTypeStateType,
  action: SubscriptionTypeActionType,
): SubscriptionTypeStateType => ({
  ...state,
  subscriptionTypesError: action.subscriptionTypesError,
  subscriptionTypesLoading: false,
});

const fetchAllSubscriptionTypesStart = (
  state: SubscriptionTypeStateType,
): SubscriptionTypeStateType => ({
  ...state,
  allSubscriptionTypesLoading: true,
});

const fetchAllSubscriptionTypesSuccess = (
  state: SubscriptionTypeStateType,
  action: SubscriptionTypeActionType,
): SubscriptionTypeStateType => ({
  ...state,
  allSubscriptionTypes: action.allSubscriptionTypes,
  allSubscriptionTypesLoading: false,
  allSubscriptionTypesError: null,
});

const fetchAllSubscriptionTypesFail = (
  state: SubscriptionTypeStateType,
  action: SubscriptionTypeActionType,
): SubscriptionTypeStateType => ({
  ...state,
  allSubscriptionTypesError: action.allSubscriptionTypesError,
  allSubscriptionTypesLoading: false,
});

const createSubscriptionTypeStart = (
  state: SubscriptionTypeStateType,
): SubscriptionTypeStateType => ({
  ...state,
  subscriptionTypeCreateLoading: true,
});

const createSubscriptionTypeSuccess = (
  state: SubscriptionTypeStateType,
  action: SubscriptionTypeActionType,
): SubscriptionTypeStateType => ({
  ...state,
  subscriptionTypeCreateLoading: false,
  subscriptionTypeCreateError: null,
  subscriptionTypeCreateSuccess: true,
  subscriptionTypesListUpdateNeeded: true,
  createdSubscriptionType: action.createdSubscriptionType,
});

const createSubscriptionTypeFail = (
  state: SubscriptionTypeStateType,
  action: SubscriptionTypeActionType,
): SubscriptionTypeStateType => ({
  ...state,
  subscriptionTypeCreateLoading: false,
  subscriptionTypeCreateError: action.subscriptionTypeCreateError,
});

const updateSubscriptionTypeStart = (
  state: SubscriptionTypeStateType,
): SubscriptionTypeStateType => ({
  ...state,
  subscriptionTypeUpdateLoading: true,
});

const updateSubscriptionTypeSuccess = (
  state: SubscriptionTypeStateType,
): SubscriptionTypeStateType => ({
  ...state,
  subscriptionTypeUpdateLoading: false,
  subscriptionTypeUpdateError: null,
  subscriptionTypeUpdateSuccess: true,
  subscriptionTypesListUpdateNeeded: true,
});

const updateSubscriptionTypeFail = (
  state: SubscriptionTypeStateType,
  action: SubscriptionTypeActionType,
): SubscriptionTypeStateType => ({
  ...state,
  subscriptionTypeUpdateLoading: false,
  subscriptionTypeUpdateError: action.subscriptionTypeUpdateError,
});

const deleteSubscriptionTypeStart = (
  state: SubscriptionTypeStateType,
): SubscriptionTypeStateType => ({
  ...state,
  subscriptionTypeDeleteLoading: true,
});

const deleteSubscriptionTypeSuccess = (
  state: SubscriptionTypeStateType,
): SubscriptionTypeStateType => ({
  ...state,
  subscriptionTypeDeleteLoading: false,
  subscriptionTypeDeleteError: null,
  subscriptionTypeDeleteSuccess: true,
  subscriptionTypesListUpdateNeeded: true,
});

const deleteSubscriptionTypeFail = (
  state: SubscriptionTypeStateType,
  action: SubscriptionTypeActionType,
): SubscriptionTypeStateType => ({
  ...state,
  subscriptionTypeDeleteLoading: false,
  subscriptionTypeDeleteError: action.subscriptionTypeDeleteError,
});

const resetSubscriptionTypeStore = (
  state: SubscriptionTypeStateType,
): SubscriptionTypeStateType => ({
  ...initialState,
});

const completelyResetSubscriptionTypeStore = (): SubscriptionTypeStateType => ({
  ...initialState,
});

const resetCreateSubscriptionTypeStore = (
  state: SubscriptionTypeStateType,
): SubscriptionTypeStateType => ({
  ...state,
  createdSubscriptionType: null,
});

const reducer = (state = initialState, action: SubscriptionTypeActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_SUBSCRIPTION_TYPES_START:
      return fetchSubscriptionTypesStart(state);
    case actionTypes.FETCH_SUBSCRIPTION_TYPES_SUCCESS:
      return fetchSubscriptionTypesSuccess(state, action);
    case actionTypes.FETCH_SUBSCRIPTION_TYPES_FAIL:
      return fetchSubscriptionTypesFail(state, action);
    case actionTypes.FETCH_ALL_SUBSCRIPTION_TYPES_START:
      return fetchAllSubscriptionTypesStart(state);
    case actionTypes.FETCH_ALL_SUBSCRIPTION_TYPES_SUCCESS:
      return fetchAllSubscriptionTypesSuccess(state, action);
    case actionTypes.FETCH_ALL_SUBSCRIPTION_TYPES_FAIL:
      return fetchAllSubscriptionTypesFail(state, action);
    case actionTypes.CREATE_SUBSCRIPTION_TYPE_START:
      return createSubscriptionTypeStart(state);
    case actionTypes.CREATE_SUBSCRIPTION_TYPE_SUCCESS:
      return createSubscriptionTypeSuccess(state, action);
    case actionTypes.CREATE_SUBSCRIPTION_TYPE_FAIL:
      return createSubscriptionTypeFail(state, action);
    case actionTypes.UPDATE_SUBSCRIPTION_TYPE_START:
      return updateSubscriptionTypeStart(state);
    case actionTypes.UPDATE_SUBSCRIPTION_TYPE_SUCCESS:
      return updateSubscriptionTypeSuccess(state);
    case actionTypes.UPDATE_SUBSCRIPTION_TYPE_FAIL:
      return updateSubscriptionTypeFail(state, action);
    case actionTypes.DELETE_SUBSCRIPTION_TYPE_START:
      return deleteSubscriptionTypeStart(state);
    case actionTypes.DELETE_SUBSCRIPTION_TYPE_SUCCESS:
      return deleteSubscriptionTypeSuccess(state);
    case actionTypes.DELETE_SUBSCRIPTION_TYPE_FAIL:
      return deleteSubscriptionTypeFail(state, action);
    case actionTypes.RESET_SUBSCRIPTION_TYPE_STORE:
      return resetSubscriptionTypeStore(state);
    case actionTypes.RESET_CREATED_SUBSCRIPTION_TYPE:
      return resetCreateSubscriptionTypeStore(state);
    case actionTypes.LOGOUT:
      return completelyResetSubscriptionTypeStore();
    default:
      return state;
  }
};

export default reducer;
