import { HttpError } from '../../config/Axios/axios-instance';
import * as actionTypes from './actionTypes';

export const subscribeNewsletterStart = () => ({
  type: actionTypes.SUBSCRIBE_NEWSLETTER_START,
});

export const subscribeNewsletterSuccess = () => ({
  type: actionTypes.SUBSCRIBE_NEWSLETTER_SUCCESS,
});

export const subscribeNewsletterFail = (
  subscribeNewsletterError: HttpError,
) => ({
  type: actionTypes.SUBSCRIBE_NEWSLETTER_FAIL,
  subscribeNewsletterError,
});

export const resetEmailStore = () => ({
  type: actionTypes.RESET_EMAIL_STORE,
});
