import * as actionTypes from './actionTypes';
import { CouponActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Coupon } from '../../domain/Coupon';
import { ListResults } from '../../common/List/List';

export type CouponStateType = {
  couponsList: ListResults<Coupon> | null;
  couponsLoading: boolean;
  couponsError: HttpError;
  couponCreateLoading: boolean;
  couponCreateError: HttpError;
  couponCreateSuccess: boolean;
  couponDeleteLoading: boolean;
  couponDeleteError: HttpError;
  couponDeleteSuccess: boolean;
  couponsListUpdateNeeded: boolean;
  createdCoupon: Coupon | null;
  validCoupon: Coupon | null;
  checkCouponLoading: boolean;
  checkCouponSuccess: boolean;
  checkCouponError: HttpError;
  profileCouponListUpdateNeeded: boolean;
  userCouponsList: ListResults<Coupon> | null;
  userCouponsListLoading: boolean;
  userCouponsListError: HttpError;
  downloadCouponLoading: boolean;
  downloadCouponError: HttpError;
  downloadCouponForUserLoading: boolean;
  downloadCouponForUserError: HttpError;
};

export type CouponActionType = CouponStateType & {
  type: CouponActionTypes;
};

export const initialState: CouponStateType = {
  couponCreateError: null,
  couponCreateLoading: false,
  couponCreateSuccess: false,
  couponDeleteError: null,
  couponDeleteLoading: false,
  couponDeleteSuccess: false,
  couponsError: null,
  couponsList: null,
  couponsLoading: true,
  couponsListUpdateNeeded: false,
  createdCoupon: null,
  validCoupon: null,
  checkCouponLoading: false,
  checkCouponSuccess: false,
  checkCouponError: null,
  profileCouponListUpdateNeeded: false,
  userCouponsList: null,
  userCouponsListLoading: false,
  userCouponsListError: null,
  downloadCouponLoading: false,
  downloadCouponError: null,
  downloadCouponForUserLoading: false,
  downloadCouponForUserError: null,
};

const fetchCouponsStart = (state: CouponStateType): CouponStateType => ({
  ...state,
  couponsLoading: true,
  couponCreateSuccess: false,
  couponDeleteSuccess: false,
});

const fetchCouponsSuccess = (
  state: CouponStateType,
  action: CouponActionType,
): CouponStateType => ({
  ...state,
  couponsList: action.couponsList,
  couponsLoading: false,
  couponsError: null,
  couponsListUpdateNeeded: false,
});

const fetchCouponsFail = (
  state: CouponStateType,
  action: CouponActionType,
): CouponStateType => ({
  ...state,
  couponsError: action.couponsError,
  couponsLoading: false,
});

const checkCouponsStart = (state: CouponStateType): CouponStateType => ({
  ...state,
  validCoupon: null,
  checkCouponLoading: true,
  checkCouponError: null,
});

const checkCouponsSuccess = (
  state: CouponStateType,
  action: CouponActionType,
): CouponStateType => ({
  ...state,
  validCoupon: action.validCoupon,
  checkCouponSuccess: true,
  checkCouponLoading: false,
  profileCouponListUpdateNeeded: true,
});

const checkCouponsFail = (
  state: CouponStateType,
  action: CouponActionType,
): CouponStateType => ({
  ...state,
  checkCouponError: action.checkCouponError,
  checkCouponLoading: false,
});

const createCouponStart = (state: CouponStateType): CouponStateType => ({
  ...state,
  couponCreateLoading: true,
  couponCreateError: null,
});

const createCouponSuccess = (
  state: CouponStateType,
  action: CouponActionType,
): CouponStateType => ({
  ...state,
  couponCreateLoading: false,
  couponCreateError: null,
  couponCreateSuccess: true,
  couponsListUpdateNeeded: true,
  createdCoupon: action.createdCoupon,
});

const createCouponFail = (
  state: CouponStateType,
  action: CouponActionType,
): CouponStateType => ({
  ...state,
  couponCreateLoading: false,
  couponCreateError: action.couponCreateError,
});

const deleteCouponStart = (state: CouponStateType): CouponStateType => ({
  ...state,
  couponDeleteLoading: true,
});

const deleteCouponSuccess = (state: CouponStateType): CouponStateType => ({
  ...state,
  couponDeleteLoading: false,
  couponDeleteError: null,
  couponDeleteSuccess: true,
  couponsListUpdateNeeded: true,
});

const deleteCouponFail = (
  state: CouponStateType,
  action: CouponActionType,
): CouponStateType => ({
  ...state,
  couponDeleteLoading: false,
  couponDeleteError: action.couponDeleteError,
});

const fetchUserCouponsStart = (state: CouponStateType): CouponStateType => ({
  ...state,
  userCouponsListLoading: true,
});

const fetchUserCouponsSuccess = (
  state: CouponStateType,
  action: CouponActionType,
): CouponStateType => ({
  ...state,
  userCouponsList: action.userCouponsList,
  userCouponsListLoading: false,
  userCouponsListError: null,
  profileCouponListUpdateNeeded: false,
});

const fetchUserCouponsFail = (
  state: CouponStateType,
  action: CouponActionType,
): CouponStateType => ({
  ...state,
  userCouponsListError: action.userCouponsListError,
  userCouponsListLoading: false,
});

const downloadCouponStart = (state: CouponStateType): CouponStateType => ({
  ...state,
  downloadCouponLoading: true,
});

const downloadCouponSuccess = (state: CouponStateType): CouponStateType => ({
  ...state,
  downloadCouponLoading: false,
  downloadCouponError: null,
});

const downloadCouponFail = (
  state: CouponStateType,
  action: CouponActionType,
): CouponStateType => ({
  ...state,
  downloadCouponError: action.downloadCouponError,
  downloadCouponLoading: false,
});

const downloadCouponForUserStart = (
  state: CouponStateType,
): CouponStateType => ({
  ...state,
  downloadCouponForUserLoading: true,
});

const downloadCouponForUserSuccess = (
  state: CouponStateType,
): CouponStateType => ({
  ...state,
  downloadCouponForUserLoading: false,
  downloadCouponForUserError: null,
});

const downloadCouponForUserFail = (
  state: CouponStateType,
  action: CouponActionType,
): CouponStateType => ({
  ...state,
  downloadCouponForUserError: action.downloadCouponForUserError,
  downloadCouponForUserLoading: false,
});

const resetCouponStore = (state: CouponStateType): CouponStateType => ({
  ...initialState,
});

const completelyResetCouponStore = (): CouponStateType => ({
  ...initialState,
});

const resetCreateCouponStore = (state: CouponStateType): CouponStateType => ({
  ...state,
  createdCoupon: null,
  couponCreateSuccess: false,
});

const resetCheckCouponStore = (state: CouponStateType): CouponStateType => ({
  ...state,
  checkCouponSuccess: false,
  validCoupon: null,
  checkCouponError: null,
});

const reducer = (state = initialState, action: CouponActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_COUPONS_START:
      return fetchCouponsStart(state);
    case actionTypes.FETCH_COUPONS_SUCCESS:
      return fetchCouponsSuccess(state, action);
    case actionTypes.FETCH_COUPONS_FAIL:
      return fetchCouponsFail(state, action);
    case actionTypes.CHECK_COUPONS_START:
      return checkCouponsStart(state);
    case actionTypes.CHECK_COUPONS_SUCCESS:
      return checkCouponsSuccess(state, action);
    case actionTypes.CHECK_COUPONS_FAIL:
      return checkCouponsFail(state, action);
    case actionTypes.CREATE_COUPON_START:
      return createCouponStart(state);
    case actionTypes.CREATE_COUPON_SUCCESS:
      return createCouponSuccess(state, action);
    case actionTypes.CREATE_COUPON_FAIL:
      return createCouponFail(state, action);
    case actionTypes.DELETE_COUPON_START:
      return deleteCouponStart(state);
    case actionTypes.DELETE_COUPON_SUCCESS:
      return deleteCouponSuccess(state);
    case actionTypes.DELETE_COUPON_FAIL:
      return deleteCouponFail(state, action);
    case actionTypes.FETCH_USER_COUPONS_START:
      return fetchUserCouponsStart(state);
    case actionTypes.FETCH_USER_COUPONS_SUCCESS:
      return fetchUserCouponsSuccess(state, action);
    case actionTypes.FETCH_USER_COUPONS_FAIL:
      return fetchUserCouponsFail(state, action);
    case actionTypes.DOWNLOAD_COUPON_START:
      return downloadCouponStart(state);
    case actionTypes.DOWNLOAD_COUPON_SUCCESS:
      return downloadCouponSuccess(state);
    case actionTypes.DOWNLOAD_COUPON_FAIL:
      return downloadCouponFail(state, action);
    case actionTypes.DOWNLOAD_COUPON_FOR_USER_START:
      return downloadCouponForUserStart(state);
    case actionTypes.DOWNLOAD_COUPON_FOR_USER_SUCCESS:
      return downloadCouponForUserSuccess(state);
    case actionTypes.DOWNLOAD_COUPON_FOR_USER_FAIL:
      return downloadCouponForUserFail(state, action);
    case actionTypes.RESET_COUPON_STORE:
      return resetCouponStore(state);
    case actionTypes.RESET_CREATED_COUPON:
      return resetCreateCouponStore(state);
    case actionTypes.RESET_CHECK_COUPON_STORE:
      return resetCheckCouponStore(state);
    case actionTypes.LOGOUT:
      return completelyResetCouponStore();
    default:
      return state;
  }
};

export default reducer;
