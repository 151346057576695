export const FETCH_SUBSCRIPTION_TYPES_START = 'FETCH_SUBSCRIPTION_TYPES_START';
export const FETCH_SUBSCRIPTION_TYPES_SUCCESS =
  'FETCH_SUBSCRIPTION_TYPES_SUCCESS';
export const FETCH_SUBSCRIPTION_TYPES_FAIL = 'FETCH_SUBSCRIPTION_TYPES_FAIL';

export const FETCH_ALL_SUBSCRIPTION_TYPES_START =
  'FETCH_ALL_SUBSCRIPTION_TYPES_START';
export const FETCH_ALL_SUBSCRIPTION_TYPES_SUCCESS =
  'FETCH_ALL_SUBSCRIPTION_TYPES_SUCCESS';
export const FETCH_ALL_SUBSCRIPTION_TYPES_FAIL =
  'FETCH_ALL_SUBSCRIPTION_TYPES_FAIL';

export const CREATE_SUBSCRIPTION_TYPE_START = 'CREATE_SUBSCRIPTION_TYPE_START';
export const CREATE_SUBSCRIPTION_TYPE_SUCCESS =
  'CREATE_SUBSCRIPTION_TYPE_SUCCESS';
export const CREATE_SUBSCRIPTION_TYPE_FAIL = 'CREATE_SUBSCRIPTION_TYPE_FAIL';

export const UPDATE_SUBSCRIPTION_TYPE_START = 'UPDATE_SUBSCRIPTION_TYPE_START';
export const UPDATE_SUBSCRIPTION_TYPE_SUCCESS =
  'UPDATE_SUBSCRIPTION_TYPE_SUCCESS';
export const UPDATE_SUBSCRIPTION_TYPE_FAIL = 'UPDATE_SUBSCRIPTION_TYPE_FAIL';

export const DELETE_SUBSCRIPTION_TYPE_START = 'DELETE_SUBSCRIPTION_TYPE_START';
export const DELETE_SUBSCRIPTION_TYPE_SUCCESS =
  'DELETE_SUBSCRIPTION_TYPE_SUCCESS';
export const DELETE_SUBSCRIPTION_TYPE_FAIL = 'DELETE_SUBSCRIPTION_TYPE_FAIL';

export const RESET_SUBSCRIPTION_TYPE_STORE = 'RESET_SUBSCRIPTION_TYPE_STORE';
export const RESET_CREATED_SUBSCRIPTION_TYPE =
  'RESET_CREATED_SUBSCRIPTION_TYPE';

export const LOGOUT = 'LOGOUT';

export type SubscriptionTypeActionTypes =
  | typeof FETCH_SUBSCRIPTION_TYPES_START
  | typeof FETCH_SUBSCRIPTION_TYPES_SUCCESS
  | typeof FETCH_SUBSCRIPTION_TYPES_FAIL
  | typeof FETCH_ALL_SUBSCRIPTION_TYPES_START
  | typeof FETCH_ALL_SUBSCRIPTION_TYPES_SUCCESS
  | typeof FETCH_ALL_SUBSCRIPTION_TYPES_FAIL
  | typeof CREATE_SUBSCRIPTION_TYPE_START
  | typeof CREATE_SUBSCRIPTION_TYPE_SUCCESS
  | typeof CREATE_SUBSCRIPTION_TYPE_FAIL
  | typeof UPDATE_SUBSCRIPTION_TYPE_START
  | typeof UPDATE_SUBSCRIPTION_TYPE_SUCCESS
  | typeof UPDATE_SUBSCRIPTION_TYPE_FAIL
  | typeof DELETE_SUBSCRIPTION_TYPE_START
  | typeof DELETE_SUBSCRIPTION_TYPE_SUCCESS
  | typeof DELETE_SUBSCRIPTION_TYPE_FAIL
  | typeof RESET_SUBSCRIPTION_TYPE_STORE
  | typeof RESET_CREATED_SUBSCRIPTION_TYPE
  | typeof LOGOUT;
