import React from 'react';
import { useIntl } from 'react-intl';
import styles from './NewsletterSubscribeModal.module.scss';
import { translate } from '../../../../utility/messageTranslator/translate';
import Modal from '../../../../common/Modal/Modal';
import surfImg from '../../../../assets/surf.png';
import NewsletterSubscribeForm from '../NewsletterSubscribeForm/NewsletterSubscribeForm';

type Props = {
  onClose: () => void;
  isOpen: boolean;
};

export const NewsletterSubscribeModal = ({ onClose, isOpen }: Props) => {
  const intl = useIntl();

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      className={styles.newsletterSubscribeModal}
    >
      <img src={surfImg} className={styles.img} />
      <div className={styles.infoSection}>
        <h1 className={styles.title}>
          {translate(intl, 'NEWSLETTER_SUBSCRIBE_MODAL.TITLE')}
        </h1>
        <p className={styles.description}>
          {translate(intl, 'NEWSLETTER_SUBSCRIBE_MODAL.DESCRIPTION')}
        </p>
        <NewsletterSubscribeForm onClose={onClose} />
      </div>
    </Modal>
  );
};

export default NewsletterSubscribeModal;
