import React from 'react';
import styles from './AuthCard.module.scss';

type Props = {
  icon: string;
  title: string;
  subTitle: string;
};

export const AuthCard = ({ icon, title, subTitle }: Props) => {
  return (
    <div className={styles.authCard}>
      <div className={styles.remainingIcon}>
        <img src={icon} />
      </div>
      <div className={styles.remainingText}>
        <div className={styles.remainingTitle}>{title}</div>
        <div className={styles.remainingSubTitle}>{subTitle}</div>
      </div>
    </div>
  );
};

export default AuthCard;
