export const routes = {
  homepage: '/',
  privacyPolicy: '/privacy-policy',
  login: '/login',
  registration: '/registration',
  forgotPassword: '/forgot-password',
  passwordReset: '/password-reset/:code',
  registrationConfirmation: '/registration-confirmation/:code',
  reservation: '/reservation',
  profile: '/profile',
  myReservations: '/profile/reservations',
  paymentsHistory: '/profile/payments',
  mySubscriptions: '/profile/subscriptions',
  myCoupons: '/profile/coupons',
  dynamicPage: '/:locale/:slug',
  admin: '/admin',
  users: {
    list: '/admin/users',
    create: '/admin/users/new',
    edit: '/admin/users/:id',
  },
  payments: {
    list: '/admin/payments',
  },
  sessionTypes: '/admin/session-types',
  subscriptionTypes: '/admin/subscription-types',
  coupons: '/admin/coupons',
  sessions: '/admin/sessions',
  translations: '/admin/translations',
  comingSoon: '/coming-soon',
  languages: '/admin/languages',
  aboutUs: '/about-us',
  contactUs: '/contact-us',
  subscriptions: '/subscriptions',
  publicCoupons: '/coupons',
  faq: '/faq',
  passwordChange: '/password-change/:token',
  newsletter: '/newsletter',
};
