import React, { useMemo } from 'react';
import styles from './Footer.module.scss';
import { translate } from '../../../../utility/messageTranslator/translate';
import Button from '../../../Button/Button';
import { routes } from '../../../../config/Router/routes';
import linkedInIcon from '../../../../assets/icons/LinkedIn.svg';
import instagramIcon from '../../../../assets/icons/instagram.svg';
import phoneIcon from '../../../../assets/icons/phone.svg';
import mailIcon from '../../../../assets/icons/mail.svg';
import locationIcon from '../../../../assets/icons/location.svg';
// import hiatusLogo from '../../../../assets/hiatus_logo.png';
import ababaLogo from '../../../../assets/ababa_logo.png';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

export const Footer = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const socials = useMemo(() => {
    return [
      {
        icon: linkedInIcon,
        link: translate(intl, 'SOCIALS.LINKEDIN'),
      },
      {
        icon: instagramIcon,
        link: translate(intl, 'SOCIALS.INSTAGRAM'),
      },
    ];
  }, []);

  const contacts = useMemo(() => {
    return [
      {
        icon: phoneIcon,
        link: translate(intl, 'FOOTER_CONTACT.PHONE_LINK'),
        label: translate(intl, 'FOOTER_CONTACT.PHONE_LABEL'),
      },
      {
        icon: mailIcon,
        link: translate(intl, 'FOOTER_CONTACT.EMAIL_LINK'),
        label: translate(intl, 'FOOTER_CONTACT.EMAIL_LABEL'),
      },
      {
        icon: locationIcon,
        link: translate(intl, 'FOOTER_CONTACT.LOCATION_LINK'),
        label: translate(intl, 'FOOTER_CONTACT.LOCATION_LABEL'),
      },
    ];
  }, []);

  const partners = useMemo(() => {
    return [
      // {
      //   icon: hiatusLogo,
      //   link: translate(intl, 'FOOTER_PARTNER.HIATUS_LINK'),
      //   label: translate(intl, 'FOOTER_PARTNER.HIATUS_LABEL'),
      // },
      {
        icon: ababaLogo,
        link: translate(intl, 'FOOTER_PARTNER.ABABA_LINK'),
        label: translate(intl, 'FOOTER_PARTNER.ABABA_LABEL'),
      },
    ];
  }, []);

  return (
    <div className={styles.footer}>
      <div className={styles.footerInner}>
        <div className={styles.footerReserveTimeContainer}>
          <div className={styles.footerReserveTimeInner}>
            <div className={styles.footerReservationText}>
              <div className={styles.footerTitle}>
                {translate(intl, 'FOOTER.TITLE')}
              </div>
              <div className={styles.footerSubtitle}>
                {translate(intl, 'FOOTER.SUBTITLE')}
              </div>
            </div>
            <div className={styles.footerReservationButton}>
              <Button onClick={() => navigate(routes.reservation)}>
                {translate(intl, 'FOOTER.BUTTON')}
              </Button>
            </div>
          </div>
        </div>

        <div className={styles.footerBottom}>
          <div className={styles.footerBottomLinksContainer}>
            <div className={styles.socialsContainer}>
              {socials.map((socialIcon, index) => (
                <a
                  key={`social-${index}`}
                  href={socialIcon.link}
                  className={styles.socialContainer}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img src={socialIcon.icon} />
                </a>
              ))}
            </div>
            <div className={styles.contacts}>
              {contacts.map((contactItem, index) => (
                <a
                  key={`contact-${index}`}
                  href={contactItem.link}
                  target="_blank"
                  rel="noreferrer noopener"
                  className={styles.contactLink}
                >
                  <img src={contactItem.icon} />
                  <span>{contactItem.label}</span>
                </a>
              ))}
            </div>
          </div>
          <div className={styles.footerLogosContainer}>
            {partners.map((partner, index) => (
              <a
                key={`partner-${index}`}
                href={partner.link}
                className={styles.partnerContainer}
                target="_blank"
                rel="noreferrer noopener"
              >
                <img src={partner.icon} />
                <span>{partner.label}</span>
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
