import React, { ReactNode } from 'react';
import styles from './AuthLayout.module.scss';
import authBackground from '../../../assets/auth/background.png';
import logo from '../../../assets/citywave-logo.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { IconButton } from '@mui/material';
import { useIntl } from 'react-intl';
import { translate } from '../../../utility/messageTranslator/translate';
import { routes } from '../../../config/Router/routes';
import { useNavigate } from 'react-router-dom';

type Props = {
  children: ReactNode;
};

export const AuthLayout = ({ children }: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <div
      className={styles.authLayout}
      style={{ backgroundImage: `url(${authBackground})` }}
    >
      <div className={styles.backdrop}>
        <div className={styles.content}>
          <div className={styles.logo}>
            <img src={logo} />
          </div>
          <div className={styles.contentInner}>
            <div className={styles.backButtonContainer}>
              <IconButton
                onClick={() => navigate(routes.homepage)}
                className={styles.backButtonIcon}
              >
                <FontAwesomeIcon
                  icon={faChevronLeft as IconProp}
                  fixedWidth
                  size="2xs"
                />
              </IconButton>
              <span onClick={() => navigate(routes.homepage)}>
                {translate(intl, 'AUTH_LAYOUT.GO_BACK')}
              </span>
            </div>
            <div className={styles.innerCard}>{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
