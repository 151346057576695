export const FETCH_USER_RESERVATIONS_LIST_START =
  'FETCH_USER_RESERVATIONS_LIST_START';
export const FETCH_USER_RESERVATIONS_LIST_SUCCESS =
  'FETCH_USER_RESERVATIONS_LIST_SUCCESS';
export const FETCH_USER_RESERVATIONS_LIST_FAIL =
  'FETCH_USER_RESERVATIONS_LIST_FAIL';

export const RESET_RESERVATION_STORE = 'RESET_RESERVATION_STORE';

export const LOGOUT = 'LOGOUT';

export type ReservationActionTypes =
  | typeof FETCH_USER_RESERVATIONS_LIST_START
  | typeof FETCH_USER_RESERVATIONS_LIST_SUCCESS
  | typeof FETCH_USER_RESERVATIONS_LIST_FAIL
  | typeof RESET_RESERVATION_STORE
  | typeof LOGOUT;
