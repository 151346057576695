import { translate } from '../messageTranslator/translate';
import { IntlShape } from 'react-intl';

export const getRemainingWord = (remainingSlots: number, intl: IntlShape) => {
  const lastDigit = remainingSlots % 10;
  const lastTwoDigits = remainingSlots % 100;

  if (remainingSlots === 0) {
    return translate(intl, 'SESSION_TYPE.CARD.REMAINING_3').replace(
      ':remaining',
      remainingSlots.toString(),
    );
  }

  if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
    return translate(intl, 'SESSION_TYPE.CARD.REMAINING_3').replace(
      ':remaining',
      remainingSlots.toString(),
    );
  }

  switch (lastDigit) {
    case 1:
      return translate(intl, 'SESSION_TYPE.CARD.REMAINING_1').replace(
        ':remaining',
        remainingSlots.toString(),
      );
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
      return translate(intl, 'SESSION_TYPE.CARD.REMAINING_2').replace(
        ':remaining',
        remainingSlots.toString(),
      );
    default:
      return translate(intl, 'SESSION_TYPE.CARD.REMAINING_3').replace(
        ':remaining',
        remainingSlots.toString(),
      );
  }
};

export const getRemainingWordForSessions = (
  remaining: number,
  intl: IntlShape,
) => {
  const lastDigit = remaining % 10;
  const lastTwoDigits = remaining % 100;

  if (remaining === 0) {
    return translate(intl, 'AUTH_CARD.REMAINING_3').replace(
      ':remaining',
      remaining.toString(),
    );
  }

  if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
    return translate(intl, 'AUTH_CARD.REMAINING_3').replace(
      ':remaining',
      remaining.toString(),
    );
  }

  switch (lastDigit) {
    case 1:
      return translate(intl, 'AUTH_CARD.REMAINING_1').replace(
        ':remaining',
        remaining.toString(),
      );
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
      return translate(intl, 'AUTH_CARD.REMAINING_2').replace(
        ':remaining',
        remaining.toString(),
      );
    default:
      return translate(intl, 'AUTH_CARD.REMAINING_1').replace(
        ':remaining',
        remaining.toString(),
      );
  }
};
