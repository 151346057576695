export const FETCH_SESSIONS_START = 'FETCH_SESSIONS_START';
export const FETCH_SESSIONS_SUCCESS = 'FETCH_SESSIONS_SUCCESS';
export const FETCH_SESSIONS_FAIL = 'FETCH_SESSIONS_FAIL';

export const CREATE_SESSION_START = 'CREATE_SESSION_START';
export const CREATE_SESSION_SUCCESS = 'CREATE_SESSION_SUCCESS';
export const CREATE_SESSION_FAIL = 'CREATE_SESSION_FAIL';

export const UPDATE_SESSION_START = 'UPDATE_SESSIONS_START';
export const UPDATE_SESSION_SUCCESS = 'UPDATE_SESSIONS_SUCCESS';
export const UPDATE_SESSION_FAIL = 'UPDATE_SESSIONS_FAIL';

export const DELETE_SESSION_START = 'DELETE_SESSION_START';
export const DELETE_SESSION_SUCCESS = 'DELETE_SESSION_SUCCESS';
export const DELETE_SESSION_FAIL = 'DELETE_SESSION_FAIL';

export const DELETE_DAY_START = 'DELETE_DAY_START';
export const DELETE_DAY_SUCCESS = 'DELETE_DAY_SUCCESS';
export const DELETE_DAY_FAIL = 'DELETE_DAY_FAIL';

export const SELECT_TIME = 'SELECT_TIME';

export const RESET_SELECT_TIME_STORE = 'RESET_SELECT_TIME_STORE';
export const RESET_SESSIONS = 'RESET_SESSIONS';
export const RESET_CREATED_SESSION_STORE = 'RESET_CREATED_SESSION_STORE';
export const RESET_UPDATED_SESSION_STORE = 'RESET_UPDATED_SESSION_STORE';

export const LOGOUT = 'LOGOUT';

export type SessionActionTypes =
  | typeof FETCH_SESSIONS_START
  | typeof FETCH_SESSIONS_SUCCESS
  | typeof FETCH_SESSIONS_FAIL
  | typeof CREATE_SESSION_START
  | typeof CREATE_SESSION_SUCCESS
  | typeof CREATE_SESSION_FAIL
  | typeof UPDATE_SESSION_START
  | typeof UPDATE_SESSION_SUCCESS
  | typeof UPDATE_SESSION_FAIL
  | typeof DELETE_SESSION_START
  | typeof DELETE_SESSION_SUCCESS
  | typeof DELETE_SESSION_FAIL
  | typeof DELETE_DAY_START
  | typeof DELETE_DAY_SUCCESS
  | typeof DELETE_DAY_FAIL
  | typeof SELECT_TIME
  | typeof RESET_SELECT_TIME_STORE
  | typeof RESET_SESSIONS
  | typeof RESET_CREATED_SESSION_STORE
  | typeof RESET_UPDATED_SESSION_STORE
  | typeof LOGOUT;
