export const FETCH_COUPONS_START = 'FETCH_COUPONS_START';
export const FETCH_COUPONS_SUCCESS = 'FETCH_COUPONS_SUCCESS';
export const FETCH_COUPONS_FAIL = 'FETCH_COUPONS_FAIL';

export const CHECK_COUPONS_START = 'CHECK_COUPONS_START';
export const CHECK_COUPONS_SUCCESS = 'CHECK_COUPONS_SUCCESS';
export const CHECK_COUPONS_FAIL = 'CHECK_COUPONS_FAIL';

export const CREATE_COUPON_START = 'CREATE_COUPON_START';
export const CREATE_COUPON_SUCCESS = 'CREATE_COUPON_SUCCESS';
export const CREATE_COUPON_FAIL = 'CREATE_COUPON_FAIL';

export const DELETE_COUPON_START = 'DELETE_COUPON_START';
export const DELETE_COUPON_SUCCESS = 'DELETE_COUPON_SUCCESS';
export const DELETE_COUPON_FAIL = 'DELETE_COUPON_FAIL';

export const FETCH_USER_COUPONS_START = 'FETCH_USER_COUPONS_START';
export const FETCH_USER_COUPONS_SUCCESS = 'FETCH_USER_COUPONS_SUCCESS';
export const FETCH_USER_COUPONS_FAIL = 'FETCH_USER_COUPONS_FAIL';

export const DOWNLOAD_COUPON_START = 'DOWNLOAD_COUPON_START';
export const DOWNLOAD_COUPON_SUCCESS = 'DOWNLOAD_COUPON_SUCCESS';
export const DOWNLOAD_COUPON_FAIL = 'DOWNLOAD_COUPON_FAIL';

export const DOWNLOAD_COUPON_FOR_USER_START = 'DOWNLOAD_COUPON_FOR_USER_START';
export const DOWNLOAD_COUPON_FOR_USER_SUCCESS =
  'DOWNLOAD_COUPON_FOR_USER_SUCCESS';
export const DOWNLOAD_COUPON_FOR_USER_FAIL = 'DOWNLOAD_COUPON_FOR_USER_FAIL';

export const RESET_COUPON_STORE = 'RESET_COUPON_STORE';
export const RESET_CHECK_COUPON_STORE = 'RESET_CHECK_COUPON_STORE';
export const RESET_CREATED_COUPON = 'RESET_CREATED_COUPON';

export const LOGOUT = 'LOGOUT';

export type CouponActionTypes =
  | typeof FETCH_COUPONS_START
  | typeof FETCH_COUPONS_SUCCESS
  | typeof FETCH_COUPONS_FAIL
  | typeof CHECK_COUPONS_START
  | typeof CHECK_COUPONS_SUCCESS
  | typeof CHECK_COUPONS_FAIL
  | typeof CREATE_COUPON_START
  | typeof CREATE_COUPON_SUCCESS
  | typeof CREATE_COUPON_FAIL
  | typeof DELETE_COUPON_START
  | typeof DELETE_COUPON_SUCCESS
  | typeof DELETE_COUPON_FAIL
  | typeof FETCH_USER_COUPONS_START
  | typeof FETCH_USER_COUPONS_SUCCESS
  | typeof FETCH_USER_COUPONS_FAIL
  | typeof DOWNLOAD_COUPON_START
  | typeof DOWNLOAD_COUPON_SUCCESS
  | typeof DOWNLOAD_COUPON_FAIL
  | typeof DOWNLOAD_COUPON_FOR_USER_START
  | typeof DOWNLOAD_COUPON_FOR_USER_SUCCESS
  | typeof DOWNLOAD_COUPON_FOR_USER_FAIL
  | typeof RESET_COUPON_STORE
  | typeof RESET_CHECK_COUPON_STORE
  | typeof RESET_CREATED_COUPON
  | typeof LOGOUT;
